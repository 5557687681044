.react-mde {
  border: 1px solid #c8ccd0;
  border-radius: 2px; }
  .react-mde * {
    box-sizing: border-box; }
  .react-mde .invisible {
    display: none; }
  .react-mde .image-tip {
    user-select: none;
    display: flex !important;
    padding: 7px 10px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: gray;
    background-color: #f9f9f9;
    border-top: 1px solid #c8ccd0;
    position: relative; }
    .react-mde .image-tip .image-input {
      min-height: 0;
      opacity: .01;
      width: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
      cursor: pointer; }
